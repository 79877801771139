import React from 'react';
import { Modal } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ModalContent from '../components/ModalContent';

export default class DialogUtils {

    /**
     * 
     * @param content 
     * @param onOk 
     * @param onCancel 
     */
    static confirm(content:any, onOk?:Function, onCancel?:Function) {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            centered: true,
            content: content,
            onOk: () => { if(onOk) onOk() },
            onCancel: () => { if(onCancel) onCancel() }
          });
    }

    
    /**
     * 
     * @param content 
     * @param onOk 
     */
     static error(content:any, onOk?:Function) {
        Modal.error({
            icon: null,
            centered: true,
            closable:false,
            className: 'ant-modal-btn-common{',
            okButtonProps: {
                className: 'ant-btn-primary',
                id: 'modal-error-btn',
                name: "Okay"
            },
            okText: "Okay",
            width: 300,
            content: <ModalContent type={'error'} content={content} />,
            onOk: () => { if(onOk) onOk() },
          });
    }


    /**
     * 
     * @param content 
     * @param onOk 
     */
    static success(content:any, onOk?:Function) {
        Modal.success({
            icon: null,
            centered: true,
            closable:true,
            className: 'ant-modal-btn-common{',
            okButtonProps: {
                className: 'ant-btn-primary',
                id: 'modal-error-btn',
                name: "Okay"
            },
            okText: "Okay",
            width: 300,
            content: <ModalContent type={'success'} content={content} />,
            onOk: () => { if(onOk) onOk() },
          });
    }
}