import React, { useEffect, useState } from 'react';
import { Col, Dropdown, Menu, Row, Space, List, Avatar, Typography } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import logo from '../../../../assets/svg/logo-text-row.svg';
import logo_icon from '../../../../assets/svg/logo-dark.svg';
import { UserOutlined, ShopOutlined, LogoutOutlined } from '@ant-design/icons';
// MachineIcon
import { ActivityGroupingIcon, ActivityListIcon, BurgerIcon, CustomersIcon, DashboardIcon, DropdownIcon, HeartIcon, MarketIcon, MerchantIcon, OrdersIcon, PayableIcon, ReservationsIcon, TipsIcon, UserIcon } from '../../../../components/icons/Icons';
import AuthService from '../../../../services/api/AuthService';


import './SideMenuView.less';

const getUser = () => {
  const data: any = localStorage.getItem('user');
  return data
}

const SideMenuView = ({ siderCollapsed, collapseToggle }: any) => {
  const location = useLocation();
  const history = useHistory();
  const [user, setUser]: any = useState();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const auth = new AuthService()

  const data = JSON.parse(getUser())
  useEffect(() => {
    const keyPath = keyPathTransformer(`/${location.pathname.split('/')[1]}`)
    setCurrentPath(keyPath);
    verifyUser()
  }, [location.pathname]);

  const verifyUser = async () => {
    if (data) {
      const isDeleted = await auth.verifyUser(data._id)
      if (isDeleted) onLogout();
    }
  }

  // useEffect(() => {
  //   if(!localStorage.getItem('user')) {
  //       onLogout();
  //   } else {
  //       const data:any = localStorage.getItem('user');
  //       setUser(JSON.parse(data));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  useEffect(() => {
    if (!data) {
      onLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const keyPathTransformer = (value: string) => {
    switch (value) {
      case '/':
      case '/merchant-management': {
          return '/merchant-management'
      }
      // case '/date-experience-management': {
      //   return '/date-experience-management'
      // }
      case '/reservations-management': {
        return '/reservations-management'
      }
      case '/activity-requests': {
        return '/activity-requests'
      }
      case '/activity-groupings': {
        return '/activity-groupings'
      }
      // case '/payables': {
      //   return '/payables'
      // }
      default: {
        return ''
      }
    }
  }


  const onLogout = () => {
    localStorage.clear();
    history.push('/login');
  }

  const profile = () => {
    history.push('/profile');
  }

  const onToggleMenus = (action: string) => {
    if (window.innerWidth <= 768) {
      collapseToggle(!siderCollapsed);
    }
    setCurrentPath(action);
    history.push(action);
  }

  const menu = (
    <Menu>
      {/* <Menu.Item key="0" className="link-secondary">
        <span onClick={() => profile()} className="c-pointer fw-600">
          <UserOutlined /> My Profile
        </span>
      </Menu.Item>
      <Menu.Item key="1" className="link-secondary">
        <span onClick={(e) => e.preventDefault()} className="c-pointer fw-600">
          <CustomerServiceFilled /> Help Center
        </span>
      </Menu.Item> */}
      <Menu.Item key="2" className="link-secondary">
        <span onClick={() => onLogout()} className="c-pointer fw-600">
          <LogoutOutlined /> Log Out
        </span>
      </Menu.Item>
    </Menu>
  );
  // const profileInfo = [{
  //     name: user ? user.first_name : null,
  //     type: user ? user.user_type : null,
  //     image: <UserOutlined />
  // }]

  const profileInfo = [{
    name: data ? data.first_name : null,
    type: data ? data.user_type : null,
    image: <UserOutlined />
  }]

  return (
    <Row className="sidebar-root">
      <Row align="middle" justify="center" style={{ width: '100%', padding: '20px 0', paddingBottom: 10, overflow: 'hidden' }}>
        {!siderCollapsed ? (<Space align="center" style={{ height: 44 }}>
          <img style={{cursor: 'pointer'}} onClick={() =>onToggleMenus('/merchant-management')} src={logo} alt="Secret Dates" width="155" height="40" />
          <BurgerIcon className="c-pointer visible-xs" onClick={() => collapseToggle(!siderCollapsed)} />
        </Space>) :
          (<Space align="center" style={{ height: 44 }}><img style={{cursor: 'pointer'}}  onClick={() =>onToggleMenus('/merchant-management')} src={logo_icon} alt="Secret Dates" width="45" height="40" /><BurgerIcon className="c-pointer  visible-xs" /></Space>)}
      </Row>
      <Menu
        mode="inline"
        selectedKeys={[currentPath]}
        defaultSelectedKeys={[currentPath === '/' ? '/merchant-management' : currentPath]}
        style={{ color: '#B2A1A1', border: 0 }}
      >
        <Menu.Item key="/merchant-management" onClick={() => onToggleMenus('/merchant-management')}>
          <MerchantIcon />
          <span >Merchant Management</span>
        </Menu.Item>
        {/* <Menu.Item key="/date-experience-management" onClick={() => onToggleMenus('/date-experience-management')}>
          <HeartIcon />
          <span >Date Experience</span>
        </Menu.Item> */}
        <Menu.Item key="/reservations-management" onClick={() => onToggleMenus('/reservations-management')}>
          <ReservationsIcon />
          <span >Reservations</span>
        </Menu.Item>
        <Menu.Item key="/activity-requests" onClick={() => onToggleMenus('/activity-requests')}>
          <ActivityListIcon />
          <span >Activities</span>
        </Menu.Item>
        <Menu.Item key="/activity-groupings" onClick={() => onToggleMenus('/activity-groupings')}>
          <ActivityGroupingIcon />
          <span >Activity Groupings</span>
        </Menu.Item>
        {/* <Menu.Item key="/payables" onClick={() => onToggleMenus('/payables')}>
          <PayableIcon />
          <span>Payables</span>
        </Menu.Item> */}
      </Menu>
      {!siderCollapsed ? (<div className="sidebar--overlay" onClick={() => collapseToggle(!siderCollapsed)}></div>) : null}
    </Row>
  );
}

export default SideMenuView