import React, { useEffect, useState } from 'react';
import { LogoutOutlined, DownOutlined } from '@ant-design/icons';
import { useHistory, withRouter } from 'react-router-dom';
import { Row, Col, Dropdown, Menu, Badge, Typography } from 'antd';
import { BurgerIcon, GearIcon, NotificationIcon, DropdownIcon } from '../../../../components/icons/Icons';

import socketIOClient from 'socket.io-client';

import './HeaderView.less';
import NotificationsView from '../../../notifications/NotificationsView';
import DynamicDialog from '../../../../components/DynamicDialog';
import AuthService from '../../../../services/api/AuthService';
import NotificationService from '../../../../services/api/ManageNotificationService';

import APPConfig from '../../../../config'

const socket = socketIOClient(APPConfig.SOCKET_URL, {
    transports: ['websocket', 'polling', 'flashsocket'],
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 99999
})

const HeaderView = (props: any) => {
    const history = useHistory();
    const notificationService = new NotificationService()

    const [user, setUser]: any = useState();
    const [isVisible, setIsVisible] = useState(false)
    const [dialogContent, setDialogContent] = useState({})
    const [modalClassname, setModalClassname]: any = useState()
    const [isReadAllNotif, setIsReadAllNotif]: any = useState()
    const [notifsDataCount, setNotifsDataCount]: any = useState([])

    useEffect(() => {

        if (!localStorage.getItem('user')) {
            onLogout();
        } else {
            const data: any = localStorage.getItem('user');
            setUser(JSON.parse(data));

            onLoadNotifications()
            socket.on('admin_notifications', () => {
                onLoadNotifications()
            })

            return () => {
                socket.disconnect()
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onLoadNotifications = async () => {
        const result = await notificationService.getAllNotification()
        const notifsCount = result?.unread_count
        setNotifsDataCount(notifsCount)
    }

    const handleAllNotifications = () => {
        props.history.push('/notifications')
        setIsVisible(false)
    }

    const handleMarkAllAsRead = async () => {
        await notificationService.markAllAsRead()
        setIsVisible(false)
    }


    const onLogout = async () => {
        // let api = new AuthService();
        // const result =  await api.logout(user._id);
        // if(result){
        localStorage.clear();
        props.history.push('/login');
        // }
    }
    const toggleSidebar = () => {
        props.collapseToggle(!props.siderCollapsed)
    }

    const customFooter = (content: string) => (
        <Row justify='center' align='middle' style={{ padding: 10 }}>
            <span style={{ cursor: 'pointer', font: 'normal normal 600 14px/18px Avenir', color: '#00AD4C' }}
               
            >{content}</span>
        </Row>
    )

    const handleShowNotifications = (e: any) => {

        setModalClassname('modal-notifications')
        setIsVisible(true)

        setDialogContent({
            footer: (
                <Row onClick={handleAllNotifications} justify='center' align='middle' style={{ padding: 10 }}>
                    <span style={{ cursor: 'pointer', font: 'normal normal 600 14px/18px Avenir', color: '#6930a3' }}>
                        See All Notifications
                    </span>
                </Row>
            ),
            title: (
                <>
                    <span>Notifications</span>
                    <a onClick={handleMarkAllAsRead}>Mark All As Read</a>
                </>
            ),
            width: 370,
            component: <NotificationsView onDismiss={() => setIsVisible(false)} />
        })
    }


    const menu = (
        <Menu className="sd-dropdown">
            <Menu.Item key="1" onClick={() => history.push("/settings")} className="link-secondary">
                <span className="c-pointer fw-600">
                    <span>Settings</span>
                </span>
            </Menu.Item>
            <Menu.Item key="2" onClick={() => onLogout()} className="link-secondary">
                <span className="c-pointer fw-600">
                    <span>Log Out
                    </span>
                </span>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <DynamicDialog
                bodyStyle={{ overflowY: 'scroll', height: 500 }}
                visibility={isVisible}
                content={dialogContent}
                closable={false}
                className={modalClassname}
                onSetVisibility={(value: boolean) => {
                    setIsVisible(value)
                }}
            />
            <Row justify="space-between" align="middle" className="sd-nav-bar bg-secondary">
                <Col>
                    <BurgerIcon className="c-pointer" onClick={toggleSidebar} />
                </Col>
                <Col className="visible-xs"><h2 className="text-secondary r-margin mobile-header">{props.pageHeader}</h2></Col>
                <Col>
                    <Row align="middle" gutter={[12, 0]} className='header-rightIcon'>
                        <Col>
                            <Badge offset={[notifsDataCount.length > 2 ? 0 : -8, 8]} className='sd-badge' count={notifsDataCount} overflowCount={99}>
                                <div className="icon-container c-pointer">
                                    <NotificationIcon onClick={handleShowNotifications} />
                                </div>
                            </Badge>
                        </Col>
                        <Col id="area">
                            <Dropdown overlay={menu} trigger={['click']} className="c-pointer hidden-xs">
                                <Row align='middle'>
                                    <p id='sd-user-name' className='text-white'>
                                        Hi. {user && (user.first_name || user.name)}
                                    </p>
                                    <DropdownIcon />
                                </Row>
                            </Dropdown>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default withRouter(HeaderView)