import React, { Component, ComponentProps, useEffect, useLayoutEffect, useState } from "react";
import "./App.css";
import axios from 'axios'
import Loader from "./components/Loader";
import lazyComponentLoader from './hoc/LazyLoader';
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation, useParams } from 'react-router-dom';
import { isArray } from "util";
import DialogUtils from "./utils/DialogUtils";
import LayoutView from "./views/layout/LayoutView";
import PublicRoute from "./hoc/PublicRoute";
import ProtectedRoute from './hoc/ProtectedRoute';
import AuthService from "./services/api/AuthService";
import WindowUtils from "./utils/WindowUtils";

interface IAppState {
  isLoading: Boolean
  errorVisible: Boolean
}
const authService = new AuthService();
const Login: any = lazyComponentLoader(() => import('./views/auth/login/LoginView'));
const Forgot: any = lazyComponentLoader(() => import('./views/auth/forgot/ForgotView'));
const Reset: any = lazyComponentLoader(() => import('./views/auth/reset/ResetView'));


// NOTIFICATIONS VIEW
const Notifications: any = lazyComponentLoader(() => import('./views/notifications/NotificationsAllView'));

//Merchant Management View
const MerchantManagement: any = lazyComponentLoader(() => import('./views/merchant-management/MerchantManagementView'))
const MerchantDetails: any = lazyComponentLoader(() => import('./views/merchant-management/MerchantDetails'))
const MerchantExperienceDetails: any = lazyComponentLoader(() => import('./views/merchant-management/MerchantExperienceDetails'))


//Date Experience Management View
const DateExperienceManagement: any = lazyComponentLoader(() => import('./views/date-experience-management/DateExperienceManagementView'))
const DateExperienceDetails: any = lazyComponentLoader(() => import('./views/date-experience-management/DateExperienceDetails'))


//Reservation Management View
const ReservationManagement: any = lazyComponentLoader(() => import('./views/reservations-management/ReservationsManagementView'))
const ReservationDetails: any = lazyComponentLoader(() => import('./views/reservations-management/ReservationDetails'))

//Activity Requests View
const ActivityRequests: any = lazyComponentLoader(() => import('./views/activity-requests/ActivityRequestsView'))
const ActivityRequestsDetails: any = lazyComponentLoader(() => import('./views/activity-requests/ActivityRequestsDetails'))

//Activity Groupings View
const ActivityGroupings: any = lazyComponentLoader(() => import('./views/activity-groupings/ActivityGroupingsView'));
const ActivityGroupDetails: any = lazyComponentLoader(() => import('./views/activity-groupings/ActivityGroupDetails'));
const AddActivityGroup: any = lazyComponentLoader(() => import('./views/activity-groupings/AddActivityGroupView'));
const ExperienceDetails: any = lazyComponentLoader(() => import('./views/activity-groupings/ExperienceDetails'));

//Payables
const Payables: any = lazyComponentLoader(() => import('./views/payables/PayablesView'))
const PayableDetails: any = lazyComponentLoader(() => import('./views/payables/PayableDetails'))

//Settings
const Settings: any = lazyComponentLoader(() => import('./views/settings/SettingsView'))
const EditSettings: any = lazyComponentLoader(() => import('./views/settings/EditSettingsView'))

//Redirect Pages
const RedirectPage: any = lazyComponentLoader(() => import('./views/redirects/RedirectPageView'))


//Route dependent local storage keys
const ACTIVITY_REQUEST_QUERY_KEY = "activity_request_experience_table_query_key"
const PAYABLES_QUERY_KEY = "payables_table_query"
const ADD_DATE_EXPERIENCE_POPUP = "date_experience_popup"
const ADD_ACTIVITY_GROUP_PAGINATION_KEY = "create_activity_group_table_pagination_key"
const ADD_ACTIVITY_GROUP_ACTIVITY_LIST = "date_experience_add"
const EDIT_ACTIVITY_GROUP_ACTIVITY_LIST = "date_experience"
const EDIT_ACTIVITY_GROUP_ACTIVITY_FORM = "date_experience_form_edit"
const ACTIVITY_GROUP_EXPERIENCE_TABLE_PAGINATION_KEY = "activity_group_experience_table_pagination_key"
const RESERVATIONS_TABLE_PAGINATION_KEY = "reservations_table_pagination_key"
const RESERVATIONS_TABLE_SEARCH_QUERY = "reservations_table_search_query"
const ACTIVITY_GROUPINGS_IMAGE_KEY_ADD = "activity_groupings_image_key_add"
const ACTIVITY_GROUPINGS_IMAGE_URL_KEY_ADD = "activity_groupings_image_url_key_add"
const MERCHANTS_ACTIVITY_TABLE_PAGINATION_KEY = "merchants_activity_table_pagination_key"
const ACTIVITY_REQUEST_TABLE_PAGINATION_KEY = "activity_request_table_pagination_key"

//Put your App Level Hooks here
//NOTE: Create your own useEffect per logic
const AppContainer = (props: ComponentProps<any>) => {
   const location = useLocation()

   //Listen for Location Changes
   useEffect(() => {
     const { pathname } = location
     
     //If outside activity requests route
     if(!pathname.includes("/activity-requests")){
       localStorage.removeItem(ACTIVITY_REQUEST_QUERY_KEY)
       localStorage.removeItem(EDIT_ACTIVITY_GROUP_ACTIVITY_LIST)
       localStorage.removeItem(ACTIVITY_REQUEST_TABLE_PAGINATION_KEY)
     }

     //If outside activity requests route
     if(!pathname.includes("/activity-groupings")){
      localStorage.removeItem(ADD_ACTIVITY_GROUP_ACTIVITY_LIST)
      localStorage.removeItem(ADD_ACTIVITY_GROUP_PAGINATION_KEY)
      localStorage.removeItem(ACTIVITY_GROUPINGS_IMAGE_URL_KEY_ADD)
      localStorage.removeItem(ACTIVITY_GROUPINGS_IMAGE_KEY_ADD)
     }

     // If outside activity groupings route or inside exact root route
    if(!pathname.includes("/activity-groupings") || pathname === "/activity-groupings"){
      localStorage.removeItem(EDIT_ACTIVITY_GROUP_ACTIVITY_LIST)
      localStorage.removeItem(ADD_DATE_EXPERIENCE_POPUP)
      localStorage.removeItem(ACTIVITY_GROUP_EXPERIENCE_TABLE_PAGINATION_KEY)
      localStorage.removeItem(ADD_ACTIVITY_GROUP_PAGINATION_KEY)
      localStorage.removeItem(EDIT_ACTIVITY_GROUP_ACTIVITY_FORM)
    }

    if(pathname === "/merchant-management" || pathname === "/"){
      localStorage.removeItem(MERCHANTS_ACTIVITY_TABLE_PAGINATION_KEY)
    }

     if(!pathname.includes("/payables")){
       localStorage.removeItem(PAYABLES_QUERY_KEY)
     }

     if(!pathname.includes("/reservations-management")){
       localStorage.removeItem(RESERVATIONS_TABLE_SEARCH_QUERY)
     }

     WindowUtils.scrollTopMainLayout()

   },[location])

   




   return <>{props.children}</>
}

let errorTimeout: any;

const App = (props: any) => {

  const [isLoading, setIsLoading] = useState(false)

  useLayoutEffect(() => {
    axios.interceptors.request.use(function (config) {
      // spinning start to show
      setIsLoading(true);

      return config
    }, function (error) {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
      // spinning hide
      setIsLoading(false);

      return response;
    }, async (error) => {
      if (error.response) {
        showError(error.response.data.errors);
      } else {
        showError(error);
      }
      setIsLoading(false);
      return Promise.reject(error);
    });
  },[])

  const showError = (errors: any) => {
    clearTimeout(errorTimeout)
    
    errorTimeout = setTimeout(() => {
      let content: any = null;
      if (isArray(errors)) {
        
        const [error] = errors
        if((error?.context?.key === 'refresh_token') || (error?.context?.key === 'access_token')){
          content = (<div>You have been logged out. Please login again.</div>)
          DialogUtils.error(content, () => {
            localStorage.clear();
            window.location.reload();
          });
          return
        }else{
          content = (<div>{error?.message}</div>)
          DialogUtils.error(content);
          return
        }

        // );
      } else {
        content = errors === undefined || errors.message.includes('Network Error') ? 'No network connection. Make sure that WI-FI or Cellular Mobile Data is turned on, then try again.' : errors.message;
      }
      // const err = content? content.split('Error: ') : '';
      DialogUtils.error(content);
    },500)

  }

  return (
    <div>
      <Router>

        <AppContainer>

          <Switch>

            <Route path='/redirect-page/:redirectUrl' component={RedirectPage}/>
            <PublicRoute
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact path='/login'
              component={Login} />
            <PublicRoute
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact path='/reset-password/:token'
              component={Reset} />
            <PublicRoute
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact path='/forgot-password'
              component={Forgot} />
            
            <LayoutView>

              {/* MERCHANT MANAGEMENT VIEW */}
              <ProtectedRoute exact path={['/', '/merchant-management']} component={MerchantManagement} />
              <ProtectedRoute exact path={['/merchant-management/:merchant_id']} component={MerchantDetails} />
              <ProtectedRoute exact 
                path={['/merchant-management/:merchant_id/date-experience/:date_experience_id']} 
                component={MerchantExperienceDetails} />


              <ProtectedRoute exact path={['/date-experience-management']} component={DateExperienceManagement} />
              <ProtectedRoute exact path={['/date-experience-management/:date_experience_id']} component={DateExperienceDetails} />

              {/* ACTIVITY REQUESTS VIEW */}
              <ProtectedRoute exact path={['/activity-requests']} component={ActivityRequests} />
              <ProtectedRoute exact path={['/activity-requests/:id']} component={ActivityRequestsDetails} />

              {/* RESERVATION MANAGEMENT */}
              <ProtectedRoute exact path={['/reservations-management']} component={ReservationManagement} />
              <ProtectedRoute exact path={'/reservations-management/bookings/:booking_id/reservations/:id'} component={ReservationDetails} />

              {/* ACTIVITY GROUPINGS VIEW */}
              <ProtectedRoute exact path={['/activity-groupings']} component={ActivityGroupings} />
              <ProtectedRoute exact path={['/activity-groupings/add']} component={AddActivityGroup} />
              <ProtectedRoute exact path={['/activity-groupings/:id/edit']} component={AddActivityGroup} />
              <ProtectedRoute exact path={['/activity-groupings/:id/:action/date-experience/:date_experience_id']} component={ExperienceDetails} />
              <ProtectedRoute exact path={['/activity-groupings/:action/date-experience/:date_experience_id']} component={ExperienceDetails} />
              <ProtectedRoute exact path={'/activity-groupings/details/:activity_group_id'} component={ActivityGroupDetails} />
              <ProtectedRoute exact path={['/activity-groupings/:action/activity-group/:activity_group_id/date-experience/:date_experience_id']} component={ExperienceDetails} />
               
               <ProtectedRoute exact path={['/redirect-page/:redirectUrl']} component={RedirectPage} />

              {/* PAYABLES MANAGEMENT VIEW */}
              <ProtectedRoute exact path={['/payables']} component={Payables} />
              <ProtectedRoute exact path={['/payables/:payable_id']} component={PayableDetails} />

              {/* NOTIFICATIONS VIEW */}
              <ProtectedRoute exact path={'/notifications'} component={Notifications} />

              {/* SETTINGS VIEW */}
              <ProtectedRoute exact path={'/settings'} component={Settings} />
              <ProtectedRoute exact path={'/settings/edit'} component={EditSettings} />

              {/* REDIRECT TO HOME/LOGIN IF NO PAGE FOUND */}
              <Route render={(props: any) => {
                const { key } = props.location;
                const isLoggedIn = authService.isLoggedIn();
                if (isLoggedIn && !key) {
                  return <Redirect from='*' to="/" />
                } else if (!isLoggedIn) {
                  return <Redirect from='*' to="/login" />
                }
              }
              } />
              {/* END */}
            </LayoutView>
          </Switch>
        </AppContainer>
      </Router>

      {isLoading ? <Loader /> : null}
    </div>
  );

}

export default App;