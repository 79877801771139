import SD_PLACEHOLDER_IMAGE from '../assets/png/sd-placeholder.png';
import SD_PLACEHOLDER_IMAGE_CARD from '../assets/png/sd-placeholder-card.png';

/**
 * App configuration
 */
class APPConfig {
    /**
     * api url endpoint
     * sample: http://localhost:3000/api
     */
    static API_URL: string = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'https://sd-api.ark.ph/api';
    // static API_URL:string ='http://localhost:3001/api';
    // static API_URL:string = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'http://localhost:3000/api';
    static DEFAULT_TIMEZONE: string = process.env.REACT_APP_DEFAULT_TIMEZONE ? process.env.REACT_APP_DEFAULT_TIMEZONE : 'Asia/Dubai';
    static SOCKET_URL: string = process.env.REACT_APP_API_SOCKET_URL ? process.env.REACT_APP_API_SOCKET_URL : 'https://sd-notif.ark.ph/';
    static GMAPS_API_KEY: string = process.env.REACT_APP_GMAPS_API_KEY ? process.env.REACT_APP_GMAPS_API_KEY : "";
    static DEFAULT_DATE_FORMAT: string = process.env.REACT_APP_DEFAULT_DATE_FORMAT ? process.env.REACT_APP_DEFAULT_DATE_FORMAT : "dd-mm-yyyy";
    static SD_PLACEHOLDER_IMAGE = SD_PLACEHOLDER_IMAGE;
    static SD_PLACEHOLDER_IMAGE_CARD = SD_PLACEHOLDER_IMAGE_CARD;
    static DATE_EXPERIENCE_CSV_HEADERS: any = {
        "Title": 'title',
        "Activity Type": 'activity_type',
        "Activity Tags": 'activity_tags',
        "Activity Image": "activity_image",
        "Po Box": 'po_box',
        "Country": 'country',
        "City": 'city',
        "Recurrence Type": 'recurrence_type',
        "Recurrence Date": 'recurrence_date',
        "Recurrence Time Start": 'recurrence_time_start',
        "Recurrence Time End": 'recurrence_time_end',
        "Recurrence Date End": 'recurrence_date_end',
        "Recurrence Selected Days": 'recurrence_selected_days',
        "Price Rating": "price_rating",
        "Price": "price",
        "Available Time": "available_time",
        "Notice Period": "notice_period",
        "Full Description": 'full_description',
        "Date Clues": 'date_clues'
    }
    static MERCHANT_CSV_HEADERS: any = {
        "Full Name": 'name',
        "Position": 'position',
        "Email": 'email',
        "Website": 'business_url',
        "Company Name": "company_name",
        "Address": 'po_box',
        "Country": 'country',
        "City": 'city',
        "Industry": 'industry',
        "Phone Number": 'phone_number',
    }
    static MERCHANT_EXPERIENCE_CSV_HEADERS: any = {
        "Full Name": 'name',
        "Position": 'position',
        "Email": 'email',
        "Website": 'business_url',
        "Company Name": "company_name",
        "Coordinates": "coordinates",
        "Country": 'country',
        "City": 'city',
        "Business Type": 'industry',
        "Business Tags": "business_tags",
        "Phone Number": 'phone_number',
        "Password": "password",
        "Merchant Image": "merchant_image",
        "Title": 'title',
        "Activity Address": "activity_address",
        "Activity Type": 'activity_type',
        "Activity Tags": 'activity_tags',
        "Activity Image": "activity_image",
        "Booking Link": "booking_link",
        "Recurrence Type": 'recurrence_type',
        "Recurrence Date": 'recurrence_date',
        // "Recurrence Time Start": 'recurrence_time_start',
        // "Recurrence Time End": 'recurrence_time_end',
        "Recurrence Date End": 'recurrence_date_end',
        "Recurrence Selected Days": 'recurrence_selected_days',
        "Price": "price",
        "Price Rating": "price_rating",
        "Available Time": "available_time",
        "Full Description": 'full_description',
        "Date Clues": 'date_clues',
        "Source": 'source',
        "Source Id": "source_id"
    }

    static _EXPERIENCE_HEADERS: Object = {
        "Title": 'title',
        "Activity Address": "activity_address",
        "Activity Type": 'activity_type',
        "Activity Tags": 'activity_tags',
        "Activity Image": "activity_image",
        "Booking Link": "booking_link",
        "Recurrence Type": 'recurrence_type',
        "Recurrence Date": 'recurrence_date',
        // "Recurrence Time Start": 'recurrence_time_start',
        // "Recurrence Time End": 'recurrence_time_end',
        "Recurrence Date End": 'recurrence_date_end',
        "Recurrence Selected Days": 'recurrence_selected_days',
        "Price Rating": "price_rating",
        "Price": "price",
        "Available Time": "available_time",
        "Full Description": 'full_description',
        "Date Clues": 'date_clues',

    }

    static PRICE_RATING_DESCRIPTIONS: any = {
        "$": "Inexpensive",
        "$$": "Moderate",
        "$$$": "Expensive",
        "$$$$": "Very Expensive"
    }

    static RECURRENCE_TYPES = ["Weekly", "Monthly", "One Time"]
}

export default APPConfig;