/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {useHistory, useParams } from 'react-router-dom';
import socketIOClient from 'socket.io-client'
import moment  from 'moment'

import { ClockCircleOutlined} from '@ant-design/icons'
import { Col, Row, Empty, Table } from 'antd'

import './NotificationsStyle.less'
import ManageNotificationService from '../../services/api/ManageNotificationService'

import APPConfig from '../../config'
const socket = socketIOClient(APPConfig.SOCKET_URL, { transports: ['websocket', 'polling', 'flashsocket'] })

const noDataPlaceholder = () => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No Notification Found'} />

const NotificationsView = ({ onDismiss }: any) => {
  const [notificationsData, setNotificationsData]: any = useState([])
  const manageNotificationService = new ManageNotificationService()
  const history = useHistory();
  
  useEffect(() => {
    loadResources()

    socket.on('admin_notifications', () => {
      loadResources()
    })

    return () => {
      socket.disconnect()
    }
  }, [])

  const loadResources = async (filter: any = '', page: any = 1, limit: any = 5) => {
    const result = await manageNotificationService.search(filter, page, limit)
    setNotificationsData(result.notifications)
    return result.notifications
  }

  const handleMarkAsRead = async (id: string) => {
    const notif = notificationsData.find((item: any) => item._id === id)
    if (!notif.read) {
      await manageNotificationService.markAsRead(id)
    }
    loadResources()
  }

  return (notificationsData || []).length ? (<>
    {
      (notificationsData || []).map((data: any) => {
        const {_id, title, description, createdAt, read, type, module_type, module_entity_id } = data
        let element

        switch (type) {
          case "admin_payable":
            element = <>
              <span className="desc">A new </span>
              <span className='title'>{title}</span>
              <span className='desc'>has been created.</span> 
            </>
            break;
          case "admin_customer_reservation":
            element = <><span className='title'>{title}</span> 
            <span className="desc">{description}</span></>
            break;
          default:
            element = <><span className='title'>{title}</span> 
            <span className="desc">{description}</span></>
            break;
        }

        return (
          <Row key={_id} className={`notifications__header-list ${!read ? 'unread' : ''} c-pointer`} onClick={async ()=> {
            if(type === "admin_customer_reservation" && module_type === "activity_booking-reservation"){
              const ids = String(module_entity_id).split("-")

              if(ids.length){
                const [activity_booking_id, reservation_id] = ids
                const notif = notificationsData.find((item: any) => item._id === _id)
                if (!notif.read) {
                  await manageNotificationService.markAsRead(_id)
                }
                if(onDismiss){
                  onDismiss()
                }
                
                if(reservation_id === "undefined"){
                  return
                }
                if(history.location.pathname.match(/reservations-management\/bookings.*reservations/g)){
                  return history.push(`/reservations-management/bookings/${activity_booking_id}/reservations/${reservation_id}`)
                }
                return history.replace(`/reservations-management/bookings/${activity_booking_id}/reservations/${reservation_id}`)
              }
            }

            handleMarkAsRead(_id)
          }}>
              <Col span={24}>
                {element ? element : null}
              </Col>
              <Col span={24} className='time-updated'>
                <ClockCircleOutlined />{moment(createdAt).fromNow()}
              </Col>
              { !read ? <span className='isRead'/> : null }
          </Row>
        )
      })
    }
  </>) : (
    <Table
      scroll={{ x: 'max-content' }}
      className='sd-pagination max-width'
      rowClassName="text-secondary"
      locale={{ emptyText: noDataPlaceholder }}
    />
  )
}

export default React.memo(NotificationsView)