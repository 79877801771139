import { RefObject } from "react"

export default class WindowUtils {

    static scrollTopMainLayout = () => {
        const mainLayout = document.getElementsByClassName("ant-layout-content")[0]
        if(mainLayout){
            mainLayout.scroll(0,0)
        }
    }

    static scrollToRef = (ref: RefObject<HTMLElement>) => {
        if(ref.current){
            ref.current.scrollIntoView()
        }
        // const tableElement = document.getElementsByClassName("sd-pagination")[0]
        // if(tableElement){
        //     tableElement.scrollIntoView({behavior: 'smooth', block: 'center'})
        // }
    }
}